body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#terms-page,
#disclosures-page {
  strong {
    font-weight: 600;
  }
  p {
    font-size: 14px;
    line-height: 1.5em;
    margin-top: 0;
    font-weight: 400;
    &.underlined {
      text-decoration: underline;
    }
    &.bold {
      font-weight: 600;
    }
  }
  h3,
  h4 {
    color: #213f7e;
    font-weight: 600;
  }
  h3 {
    font-size: 14px;
    line-height: 1.5em;
  }
  h4 {
    font-size: 12px;
    line-height: 14px;
  }

  .title {
    color: #213f7e;
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 15px;
    margin-bottom: 30px;
    display: block;
    font-size: 24px;
    line-height: 33px;
  }
  .text-secondary-blue-light {
    color: #21b0e8;
  }
}
#terms-page {
  ul {
    padding: 0;
    margin: 0 0 20px;
    list-style-type: circle;
    list-style-position: outside;
    margin-left: 40px;
    li {
      margin-bottom: 5px;
    }
  }
}
#disclosures-page {
  ul {
    padding: 0;
    &.parenthesis-letters {
      counter-reset: my-counter;
      list-style: none;

      & > li {
        margin-left: 30px;
        margin-bottom: 5px;

        &:before {
          content: '(' counter(my-counter, upper-latin) ') ';
          counter-increment: my-counter;
          position: absolute;
          margin-left: -30px;
          font-weight: 600;
        }
      }
    }
  }
}
