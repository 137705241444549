.tree {
}

.branch {

}

.leaf {

}

.inactive {
  opacity: 0.7;
  font-style: italic;
  text-decoration: line-through;
}

.arrowDown {
  cursor: pointer;
  transform: rotate(90deg);
  transition: transform 0.25s ease-out;
}

.arrowRight {
  cursor: pointer;
  transform: rotate(0);
  transition: transform 0.25s ease-out;
}

.label {
  cursor: pointer;
  padding: 2px 4px;
  transition: background 0.25s ease-in-out;
  &:hover {
    background: hsla(0,0%,100%,.8);
  }
}