.closed {
  z-index: 20;
  width: 100vw;
  @media (min-width: 576px) {
    width: 360px;
  }
  background: white;
  opacity: 0;
  transition-property: visibility, opacity, transform;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.open {
  opacity: 1;
}
