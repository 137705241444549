/* Tree */

.rd3t-grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.rd3t-grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* Node */
.rd3t-node {
  cursor: pointer;
  fill: none !important;
  stroke: none !important;
}

.icon-container rect {
  fill: #FFF;
  transform: translate(-100px, 0);
  width: 200px;
  height: 54px;
}

.icon-container text {
  fill: #222;
  font-size: 12px;
  text-anchor: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transform: translate(0, 46px);
}

.rd3t-leaf-node {
  cursor: pointer;
  fill: transparent;
  stroke: none !important;
  stroke-width: 1;
}

.rd3t-label__title {
  fill: #000;
  stroke: none;
  font-weight: bolder;
}

.rd3t-label__attributes {
  fill: #777;
  stroke: none;
  font-weight: bolder;
  font-size: smaller;
}

/* Link */
.rd3t-link {
  fill: none;
  stroke: #000;
}
