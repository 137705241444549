.tree {
  display: block;
}

.branch {
  display: block;
}

.leaf {
  display: block;
}

.node {
  display: block;
}

.inactive {
  opacity: 0.7;
  font-style: italic;
  text-decoration: line-through;
}

.arrowDown {
  cursor: pointer;
  transform: rotate(90deg);
  transition: transform 0.25s ease-out;
}

.arrowRight {
  cursor: pointer;
  transform: rotate(0);
  transition: transform 0.25s ease-out;
}

.label {
  cursor: pointer;
  padding: 2px 4px;
  transition: background 0.25s ease-in-out;
  &:hover {
    background: hsla(0,0%,100%,.8);
  }
}

nav {
  .arrowDown {
    padding: 8px 0;
  }

  .arrowRight {
    padding: 8px 0;
  }
  .node {
    padding: 0;
    line-height: 36px;
    transition: background 0.25s ease-in-out;
    &:hover {
      background: rgba(118, 134, 138, 0.04);
    }
    cursor: pointer;
  }
  .label {
    padding: 8px 4px;
    &:hover {
      background: transparent;
    }
  }
  .selected {
    background: rgba(118, 134, 138, 0.24);
  }
}